//jshint esversion:6

 const conn = "https://wetterumschwung.de/";
 const client = encodeURI('Körpertherapie Uwe Barthel');
 const perPage =1;

 const allInfos = () => {
   let infos =[];
   $('#ajax-status').show();
  $.getJSON(conn + "api/aktuelles/" + client + "?callback=?")
   .done(function(data){
      $('.ajax-status').hide();
      // infos = data; 
      document.getElementById('aktuelles-heads').innerHTML = fillHeads(data);
      document.getElementById('aktuelles-content').innerHTML = fillTemplate(data);
      if(data.length > perPage){
        pagination(data);
      }
      showSinglePost();
   })
  .fail(function( jqxhr, textStatus, error ) {
    console.log("Error ", error);
  });

 };
 const headsOnly = () => {
   let infos =[];
   // $('#ajax-status').show().text("Lade Informationen ...");
   $('#ajax-status').show();
  $.getJSON(conn + "api/aktuelles/" + client + "?callback=?")
   .done(function(data){
      infos = data; 
      document.getElementById('aktuelles-heads').innerHTML = fillHeads(data, 'aktuelles-heads');
      $('#ajax-status').hide();
   })
  .fail(function( jqxhr, textStatus, error ) {
    console.log("Error ", error);
  });

 };

 let title = (info) => {
     return `<div class='data slideInLeft' id='info-${info.id}'>
     <div class='titel'>
       ${info.title}
     </div>\n` ;
 };

 let content=(info) =>{
    return `<div class="">
      ${marked(info.content)}
    </div>\n`;
  
 };


 let attachment = (info) => {
   if(info.attachment_file_name !== 'undefined' && info.attachment_file_name !== null && info.attachment_file_name !== '')
   {
    if (info.attachment_content_type.match(/image/i)) {
     return `
       <div class="attachment">\n
       <a data-toggle='lightbox' href='${conn}docs/${client}/original/${info.attachment_file_name}'> 
       <img alt='${info.attachment_file_name}' src='${conn}docs/${client}/thumb/${info.attachment_file_name}' />
       </a>
       </div>\n
       </div>\n`;
    } else{
      // z.B. eine Pdf
       return `
         <div class="attachment">\n
           <a target='_blank' href='${conn}docs/${client}/original/${info.attachment_file_name}'> 
             ${info.attachment_file_name}
           </a>
         </div>\n
        </div>\n`;
    
    }    
   }else{
     // end of div data
     return "</div>";
   }
 };
 
 
 let pagination = (data) => {
   let currentPage = 0;
   let $data = $('div#aktuelles-content div.data');
   let count = $data.length;
   if(count > perPage){

    let pages = Math.ceil(count / perPage);
    let $pager = $('<div class="pager"></div>');

    for (var page = 0; page < pages; page++) {
        $('<span class="page-number"></span>').text(page + 1).on('click touchstart',{newPage: page}, markCurrentPage) 
        .appendTo($pager).addClass('clickable');
        if (!$('.pager').length) {
            $pager.insertAfter($('#aktuelles-content')).find('span.page-number:first').addClass('active');
        }
    }
     repaginate(currentPage, perPage);
  }
 };

 function markCurrentPage(event){
   let currentPage = event.data.newPage;
   repaginate(currentPage, perPage);
   $(this).addClass('active').siblings().removeClass('active');
   markActiveInSidebar(currentPage +1);
 }

  function repaginate(currentPage, perPage){
    $('div#aktuelles-content div.data').hide().slice(currentPage * perPage, (currentPage +1) * perPage).show();
  }

 let fillTemplate= (data) =>{
   let t = "";
   for(var i=0; i < data.length ; i++){
     t+= title(data[i]);
     t+= content(data[i]);
     t+= attachment(data[i]);
   }
   return t;
 };

function fillHeads(data, className='aktuelles-heads'){
   let t = `
     <div class=${className}>
     <div class='slideInRight'>
     <ul class='as'>\n`;
   for(var i=0; i < data.length ; i++){
     t+= `<li><a href='#info-${data[i].id}'>${data[i].title}</a></li>\n`;
   }
   t+="</ul>\n</div>\n</div>\n";
   return t;
 }

function addLinks(){
   $(document).on('click', '.aktuelles-heads a', function(e){
       $('.data').hide();
       var href = $(this).attr('href');
       fadeInArticle(href);
       var pageNo = extractPageNumber(href); //parseInt(href.slice(href.indexOf('-',0) +1)) +1 ;
       setActiveInPager(pageNo);
       // $(this).addClass('active').siblings().removeClass('active');
       $(this).parent('li').addClass('active').siblings().removeClass('active');
       e.stopPropagation();
   });
  }
  function extractArticleId(href){
    return href.slice(href.indexOf('#',0)+1);
  }

  function extractPageNumber(href){
    // extract from link not from hash
    // var articleId = href.slice(href.indexOf('#',0)+1);
    var articleId = extractArticleId(href);
    var  currentDiv = document.getElementById(articleId);      
    var  pageNo =  parseInt($('.data').index(currentDiv)) +1;
    return pageNo;
  }

  function fadeInArticle(href){
        return $(href.substring(href.indexOf('#'))).fadeIn('fast');
  }
  
  function setActiveInPager(pageNo){
    $('span.page-number').filter(function(i){
              return ((i+1) === pageNo);
           }).addClass('active').siblings().removeClass('active');
  }

  function showSinglePost(){
    if (window.location.hash){
      var hash = window.location.hash;
      var pageNo =1;
      if(hash !==""){
        $('.data').hide();
        $(hash).fadeIn('fast');
        // set right pager active
        var articleId = hash.substring(1, hash.length);
        var currentDiv = document.getElementById(articleId);      
        pageNo =  parseInt($('.data').index(currentDiv)) +1 ;
        // find matching span
        $('span.page-number').filter(function(i){
          return ((i+1) === pageNo);
        }).addClass('active').siblings().removeClass('active');
      }
      markActiveInSidebar(pageNo);
    }
  }
  function markActiveInSidebar(pageNo){
    var xref = ".aktuelles-heads ul li:nth-child(" + pageNo +")";
       setTimeout(function(){
         // aktuelles-heads needs to establish the dom elements
         $(xref).addClass('active').siblings().removeClass('active');
       }, 300);
  }



// let url = window.location.href;
// let uri = url.substring(url.lastIndexOf('/')+1);
// if (uri.match(/aktuelles.html/)){
  allInfos();
  
  addLinks();
  // markActiveInSidebar(1);
   $(document).on('click', '#aktuelles-heads a', function(e){
     $('.data').removeClass('activated');
     var h = $(this).attr('href');
     var $target = $(h);
     var offset = $target.offset().top -170;
   $('html, body').stop().animate({scrollTop: offset}, 800, function(){
     $target.addClass('activated');
   });
    e.preventDefault();
  });


